import {Injectable} from '@angular/core';
import * as signalR from "@microsoft/signalr";
import {HubConnection, HubConnectionState} from "@microsoft/signalr";
import {ACCESS_TOKEN_KEY} from "../../auth/configs/auth.config";
import {Observable, of, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private hubsConnections: {
    url: string,
    hubConnection: HubConnection
  }[] = [];

  constructor() {
  }

  public startConnection = (hubUrl: string) => {
    if (!hubUrl)
      return;

    const hubConnectionInfo = this.hubsConnections.find(c => c.url === hubUrl);

    if (hubConnectionInfo) {
      if (hubConnectionInfo?.hubConnection.state === HubConnectionState.Connected
        || hubConnectionInfo?.hubConnection.state === HubConnectionState.Connecting)
        return;

      hubConnectionInfo.hubConnection.start().catch((err) => console.error(err));
      return;
    }

    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (token) {
      const hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(hubUrl, {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
          accessTokenFactory: () => token
        })
        .configureLogging(signalR.LogLevel.None)
        .build();

      hubConnection.start().catch((err) => console.error(err));

      this.hubsConnections.push({
        url: hubUrl,
        hubConnection: hubConnection
      })
    } else {
      console.warn('Для установления связи с сервером необходимо авторизоваться');
    }
  }

  public disconnect(hubUrl: string) {
    const connection = this.hubsConnections.find(c => c.url === hubUrl);
    if (!!connection && connection.hubConnection.state === HubConnectionState.Connected) {
      connection.hubConnection.stop().catch((err) => console.error(err));
    }
  }

  public on(hubUrl: string, method: string): Observable<any> {
    const connection = this.hubsConnections.find(c => c.url === hubUrl);
    if (!!connection?.hubConnection) {
      const subject = new Subject<any>();

      connection.hubConnection.on(method, data => {
        return subject.next(data);
      });

      return subject.asObservable();
    } else {
      return of(null);
    }
  }

  public invoke(hubUrl: string, method: string, ...data: any): Promise<any> {
    const connection = this.hubsConnections.find(c => c.url === hubUrl);
    if (connection?.hubConnection.state === HubConnectionState.Connected) {
      return connection.hubConnection.invoke(method, ...data);
    }
    return Promise.reject(`Метод ${method} не может быть выполнен - отсутствует подключение к ${hubUrl}.`);
  }
}
